import { json, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Login from "./components/auth/Login";
import Dashboard from "./components/Dashboard";
import Level3Menu from "./components/Level3Menu";
import SignUp from "./components/auth/SignUp";
import AuthHomeScreen from "./components/auth/AuthHomeScreen";
import ForgotPassword from "./components/auth/ForgotPassword";
import ResetPassword from "./components/auth/ResetPassword";
import Contents from "./components/Contents";
import MyProfile from "./components/MyProfile";
import Layout from "./components/common/Layout";
import Level1Menu from "./components/Level1Menu";
import Level2Menu from "./components/Level2Menu";
import ImageModal from "./modal/ImageModal";
import VerifiyEmail from "./components/auth/VerifiyEmail";
import useAuth from "./hooks/useAuth";
import AuthProvider from "./providers/AuthProvider";
import ScrollToTop from "./ScrollToTop";
import RichEditor from "./components/RichEditor";
import Collaborators from "./components/Collaborators";
import { useEffect, useReducer } from "react";
import { ImageContext } from "./contexts/ImageContext";
import PreviewModel from "./components/PreviewModel";
import UserList from "./components/Users/UserList";
import InsertUser from "./components/Users/InsertUser";
import EditUser from "./components/Users/EditUser";

function PublicRoute({ children }) {
  let auth = useAuth();

  if (auth.user) {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
}
function PrivateRoute({ children }) {
  let auth = useAuth();

  if (!auth.user) {
    return <Navigate to="/login" replace />;
  }
  return children;
}
function AdminRoute({ children }) {
  let auth = useAuth();

  if (auth.user.role !== "Admin") {
    return <Navigate to="/dashboard" replace />;
  }
  return children;
}

const imageInitialState = { images: [] };

function imageReducer(state, action) {
  switch (action.type) {
    case "add":
      return {
        images: [
          ...state.images,
          {
            url: action.url,
            imageId: action.imageId,
          },
        ],
      };
    case "clear":
      return imageInitialState;
    default:
      throw new Error();
  }
}

function App() {
  const [imageState, imageDispatch] = useReducer(
    imageReducer,
    imageInitialState
  );
  let location = useLocation();

  return (
    <ImageContext.Provider
      value={{
        imageState: imageState,
        imageDispatch: imageDispatch,
      }}
    >
      <AuthProvider>
        {location.state &&
        (location.state.imageId || location.state == "close") ? (
          <></>
        ) : (
          <ScrollToTop />
        )}
        <Routes>
          <Route
            path="/"
            element={
              <PublicRoute>
                <AuthHomeScreen />
              </PublicRoute>
            }
          />
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          {/* <Route
            path="/signup"
            element={
              <PublicRoute>
                <SignUp />
              </PublicRoute>
            }
          /> */}
          <Route
            path="/forgot-password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/collaborators" element={<Collaborators />} />
          <Route
            path="/my-account"
            element={
              <PrivateRoute>
                <MyProfile />
              </PrivateRoute>
            }
          />
          <Route path="/verify-email" element={<VerifiyEmail />} />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/users"
            element={
              <AdminRoute>
                <UserList />
              </AdminRoute>
            }
          />
          <Route
            path="/user/create-user"
            element={
              <AdminRoute>
                <InsertUser />
              </AdminRoute>
            }
          />
          <Route
            path="/user/:id"
            exact
            element={
              <AdminRoute>
                <EditUser />
              </AdminRoute>
            }
          />
          <Route element={<Layout />}>
            <Route
              path="/:level1"
              element={
                <PrivateRoute>
                  <Level1Menu />
                </PrivateRoute>
              }
            />
            <Route
              path="/:level1/:level2"
              element={
                <PrivateRoute>
                  <Level2Menu />
                </PrivateRoute>
              }
            />
            <Route
              path="/:level1/:level2/:level3"
              element={
                <PrivateRoute>
                  <Level3Menu />
                </PrivateRoute>
              }
            />
            <Route
              path="/:level1/:level2/:level3/:level4"
              element={
                <PrivateRoute>
                  <Contents />
                </PrivateRoute>
              }
            >
              <Route
                path="image/:imageId"
                element={
                  <PrivateRoute>
                    <ImageModal />
                  </PrivateRoute>
                }
              ></Route>
            </Route>
          </Route>

          <Route
            path="/edit/:level1/:level2/:level3/:level4/:level5"
            exact
            element={
              <PrivateRoute>
                <AdminRoute>
                  <RichEditor />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/edit/:level1/:level2/:level3/:level4"
            exact
            element={
              <PrivateRoute>
                <AdminRoute>
                  <RichEditor />
                </AdminRoute>
              </PrivateRoute>
            }
          />
          <Route
            path="/torso/abdomen"
            exact
            element={
              <PrivateRoute>
                <PreviewModel />
              </PrivateRoute>
            }
          />
        </Routes>
      </AuthProvider>
    </ImageContext.Provider>
  );
}

export default App;
